import companyDatabase from "./database";
import _ from "lodash";

const getCompanyData = (arg) => {
	const createRetItem = (companyKey) => {
		const retData = companyDatabase[companyKey];

		if (!retData)
			throw `Error in getCompanyData() -- unknown key "${companyKey}" `;
		return retData;
	};

	if (_.isArray(arg)) {
		return arg.map(createRetItem);
	}

	return createRetItem(arg);
};

export default { getCompanyData };
