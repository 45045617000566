import App from "./App";
import ReactDom from "react-dom";
export default App;
import config from "config";

if (module.hot) {
	if (config.debug.clearConsoleOnRefresh) console.clear();
	module.hot.accept();

	console.log("****************************************************");
	console.log("HOT RELOAD");
	console.log("process.env.NODE_ENV=", process.env.NODE_ENV);
	console.log("****************************************************");
}

ReactDom.render(App, document.getElementById("root"));
