import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeader from "siteLayouts/Page/templates/Header";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

import { EmailLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeader headerText="Partners">

	</LayoutPageTemplateHeader>
);

const Partners = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header}>
			<LayoutPageTemplateSection>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection>

			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default Partners;
