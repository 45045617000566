import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("women-in-finance");
import { EmailLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}
	></LayoutPageTemplateHeaderImg>
);

const WomenInFinance = (props) => {
	// const { children } = props;

	return (
		<LayoutPage
			Header={Header}
			headerImage={assets.headerImage}
			className={assets.className}
		>
			<LayoutPageTemplateSection headerText="Women in Finance Charter">
				<p>
					The Women in Finance Charter is a commitment by HM Treasury and
					signatory firms to work together to build a more balanced and fair
					industry. Firms that sign up to this Charter are pledging to be the
					best businesses in the sector. Freedom Services Group signed up to the
					charter in 2020 with a 49% female representation across all employees,
					and we’ve been committed to improving this year on year.
				</p>

				<p>
					As of December 2022, we have a 49% female representation across all
					our employees, with 34% female representation in our senior leadership
					team.
				</p>
				<p>
					We have achieved our recent Charter target within the deadline and for
					over the next year, we expect to maintain the current balance between
					female and male employees throughout the business and in our senior
					leadership team.
				</p>
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default WomenInFinance;
