import React from "react";

import { NavDropdownContent } from "siteComponents";

const NavDropdown = (props) => {

    const { isDiv, navItemClass, linkText, className, items } = props;

    const navClass = !navItemClass || navItemClass === undefined ? "nav-item dropdown" : `nav-item dropdown ${navItemClass}`;

    if (isDiv) {
        return (
            <div className={navClass}>
                <NavDropdownContent linkText={linkText} className={className} items={items} />
            </div>
        );
    }

    return (
        <li className={navClass}>
            <NavDropdownContent linkText={linkText} className={className} items={items} />
        </li>
    );

}

export default NavDropdown;