import React from "react";

import siteFeeds from "siteManagers/feed";
import FeedViewer from "siteManagers/feed/Viewer";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";
import companyManager from "siteManagers/companies";

import { TrustBox, HyperlinkGoto, CompanyItem } from "siteComponents";

const assets = require("siteAssets/pageManager").default("landing");
const companyList = companyManager.getCompanyData([
	"pukka",
	"action",
	"freedomBrokers",
]);

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const LandingView = (props) => {
	
	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>
			<LayoutPageTemplateSection
				headerText="Your journey. Our protection">

				<p>
					We are the Freedom Group, and we are passionate about keeping our customers, 
					employees and partners safe and protected throughout all of life's journeys.
				</p>
				<p>
					We all know that life can be hard at times, and we aim to lighten the load by
					providing a service that's clear and straightforward, and being there to support
					and resolve issues when our customers need us. As part of our tribe, we genuinely
					care about making real human connections with our customers,
					understanding what they need from us, and being that safe pair of hands by doing 
					what we say we're going to do. 
				</p>
				<p>
					At the forefront of technology, our focus is look for new and innovative ways 
					to improve our products and services and processes to make busy lives easier, 
					and enabling people to connect with each other and the world around them. 
					Protecting through a first class claims process, providing guidance and advice 
					on how to be a safer driver, and using the latest technology to facilitate the 
					most important factor - helping people to get home safe.
				</p>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="Who we are"
			>
				<div className="row row-cols-1 row-cols-lg-3 mt-5">
					{companyList.map((x) => (
						<div className="pb-5" key={x.displayName}>
							<CompanyItem {...x} />
						</div>
					))}
				</div>

				<div className="row justify-content-center">
					<div className="col-12 col-sm-6 d-flex justify-content-center">
						<HyperlinkGoto route="/about-us/our-company" label="Find out more" className="btn btn-lg btn-primary" />
					</div>
				</div>

			</LayoutPageTemplateSection>

			{/* 
			<LayoutPageTemplateSection
				headerText="Latest news"
			>
				<div className="row row-cols-1 row-cols-md-2 row-cols-xl-4 mt-5 latest-news-teaser">
					<FeedViewer
						func={siteFeeds.getFeed("latestNewsTeaser", {
							useDisplayComponent: true,
							timeStampKey: "timeStamp",
						})}
						LoadingComponent={() => (
							<div className="alert alert-info">Checking for updates...</div>
						)}
					/>
				</div>
			</LayoutPageTemplateSection>
			*/}

			{/*
			<LayoutPageTemplateSection
				headerText="Our culture"
				bgColour="lightgrey"
			>
				<div className="fs-4 fst-italic ps-3 mb-3">
					Meritocracy, honesty and innovation are at the heart of who we are and how we behave. 
					That's why our customers, team and partners trust us. We celebrate and encourage individuality 
					and diversity, as we understand the importance of being able to be who you are at work. Our 
					environment is open and transparent and our colleagues have genuine affection and trust for 
					each other. We have the ability to laugh at ourselves, and it's safe to fail. Our team mates 
					become part of the Freedom family, and we have a deep respect for their family - that's why 
					we are committed to finding new ways to work more flexibly and smarter.
				</div>
				<div className="fs-4 fw-bold">
					Would you like to join our team? See our latest <HyperlinkGoto route="/Careers" label="job opportunities" />.
				</div>
			</LayoutPageTemplateSection>
			*/}

			{/*
			<LayoutPageTemplateSection
				headerText="Reviews"
				className="reviews"
			>
				<div className="mt-5">
					<TrustBox template="stars" />
				</div>

			</LayoutPageTemplateSection>
			*/}

		</LayoutPage>
	);
};

export default LandingView;
