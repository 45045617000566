import React from "react";

import { NavLink, ExternalLink } from "siteComponents";

const NavDropdownContent = (props) => {
    const { linkText, className, items } = props;

    const linkClassName = !className || className === undefined ? "nav-link dropdown-toggle" : `dropdown-toggle ${className}`;

    return (
        <>
            <a className={linkClassName} href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                {linkText}
            </a>
            <ul className="dropdown-menu text-end" aria-labelledby="navbarDropdown">
                {items.map((x, i) => {
                    if (x.label === "---") {
                        return (
                            <li key={i}>
                                <hr className="dropdown-divider" />
                            </li>
                        );
                    }

                    if (x.type === "heading") {
                        return (
                            <li key={i} className="nav-section-header text-uppercase px-3">
                                {x.label}
                            </li>
                        );
                    }

                    if (x.route.indexOf("http") > -1) {
                        return (
                            <li key={i} className="">
                                <ExternalLink href={x.route} label={x.label} className="dropdown-item" />
                            </li>
                        );
                    }

                    return (
                        <li key={i}>
                            <NavLink label={x.label} routepath={x.route} className="dropdown-item" />
                        </li>
                    )
                })}
            </ul>
        </>
    );
}

export default NavDropdownContent;