import React, { useCallback } from "react";
import moment from "moment";

import { ExternalLink, BaseCard, Header, Title, Body, Footer } from "siteComponents";

const PodcastDisplayComponent = (props) => {
	const { title, description, url, timeStamp, className } = props;

	const _className = [
		"sam-podcast-item",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<>
			<BaseCard cardClasses={_className}>
				<Body>
					{moment(timeStamp).format("DD MMM yy")}
				</Body>
				<Body titleContent={(<Title content={title} centerTitle={false} />)}>
					{description}
				</Body>
				<Footer isCentered={false} className="">
					<ExternalLink href={url} label="Listen" className="btn btn-fb" />
				</Footer>
			</BaseCard>
		</>
	);
};

export default PodcastDisplayComponent;
