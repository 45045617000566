import React from "react";

const Indicators = (props) => {
    const { target, slides, className } = props;

    const _className = [
        "carousel-indicators",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <div className="carousel-indicators">
            {slides.map((slide, i) => {
                return (
                    <button key={i} type="button"
                        data-bs-target={target}
                        data-bs-slide-to={`${i}`}
                        className={`${(i === 0 ? "active" : "")}`}
                        aria-current="true"
                        aria-label={`Slide ${i + 1}`}>
                    </button>
                );
            })}
        </div>
    );
}

const PrevButton = (props) => {

    const { target, className } = props;

    const _className = [
        "carousel-control-prev",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <button
            className={_className}
            type="button"
            data-bs-target={target}
            data-bs-slide="prev"
        >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
    );
}

const NextButton = (props) => {

    const { target, className } = props;

    const _className = [
        "carousel-control-next",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <button
            className={_className}
            type="button"
            data-bs-target={target}
            data-bs-slide="next"
        >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    );
}

const Controls = (props) => {
    const { target, overSlide = false, showControls = false, showIndicators = true, slides } = props;

    if (overSlide) {
        if (showIndicators) {
            return (
                <Indicators target={target} slides={slides} />
            );
        }

        if (showControls) {
            return (
                <>
                    <PrevButton target={target} className="" />
                    <NextButton target={target} className="" />
                </>
            );
        }

        return null;
    }

    if (showControls) {
        return (
            <div className="d-flex justify-content-center mb-4">
                <PrevButton target={target} className="position-relative" />

                {showIndicators && (
                    <Indicators target={target} slides={slides} className="position-relative mb-0 pt-1" />
                )}

                <NextButton target={target} className="position-relative" />                
            </div>
        );
    }

    if (showIndicators) {
        return (
            <Indicators target={target} slides={slides} className="position-relative mb-0 pt-1" />
        );
    }

    return null;
}

const Carousel = (props) => {
    const { carouselId, className = "", slides, slideInterval = "5000", controlPosition = 1, showControls = true, showIndicators = true } = props;

    if (slides == undefined) {
        return null;
    }

    const controlsBottom = controlPosition === 1 && (showControls == true || showIndicators == true);
    const controlsTop = controlPosition === 2 && (showControls == true || showIndicators == true);
    const controlsOver = controlPosition === 3 && (showControls == true || showIndicators == true);

    const carouselTarget = `#${carouselId}`;

    const _className = [
        "carousel",
        "slide",
        "carousel-dark",
        controlsOver ? "" : "",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (

        <div id={carouselId} className={`carousel slide carousel-dark ${className}`} data-bs-ride="carousel">

            {controlsOver && (
                <Controls target={carouselTarget} slides={slides} overSlide={true} showControls={false} showIndicators={true} />
            )}

            {controlsTop && (
                <Controls target={carouselTarget} slides={slides} showControls={showControls} showIndicators={showIndicators} />
            )}

            <div className="carousel-inner py-4">

                {slides.map((slide, i) => {
                    return (
                        <div key={i} className={`carousel-item ${(i === 0 ? "active" : "")}`} data-bs-interval={slideInterval}>
                            {slide}
                        </div>
                    );
                })}
            </div>

            {controlsBottom && (
                <Controls target={carouselTarget} slides={slides} showControls={showControls} showIndicators={showIndicators} />
            )}

            {controlsOver && (
                <Controls target={carouselTarget} slides={slides} overSlide={true} showControls={true} showIndicators={false} />
            )}


        </div>
    );
};

export default Carousel;