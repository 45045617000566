import React from "react";

const EmailLink = (props) => {

	const { emailAddress, altLabel, className = "", nowrap = true, isStrong = true } = props;

	const nowrapClass = nowrap ? "whitespace-no-wrap" : "";
	const displayText = altLabel != undefined ? altLabel : emailAddress;
	const display = isStrong ? <strong>{displayText}</strong> : <>{displayText}</>;

	return (
		<a
			className={`email-link  ${nowrapClass} ${className}`}
			href={`mailto:${emailAddress}`}>
			{display}
		</a>
	);
}

export default EmailLink;