import React from "react";

import "siteAssets/css/layout";

import PageNav from "./PageNav";
import PageFooter from "./PageFooter";

import menuData from "../../App/Routes/menuData";

const LayoutPage = (props) => {
	const { Header, children, className, headerImage } = props;

	const pageClass = [
		"layout",
		"page",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	const _pageIntroClass = [
		"row",
		"page-intro",
		headerImage ? "justify-content-center header-img" : "",
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	const _pageIntroStyle =
		headerImage ? { backgroundImage: `url('${headerImage}')` } : undefined;

	return (
		<>
			<PageNav menuData={menuData} />

			<div className="container-fluid">
				<div className={pageClass}>
				
					<div className={_pageIntroClass} style={_pageIntroStyle}>

						{headerImage && (
							<div className="col-12 d-flex justify-content-evenly justify-content-lg-start">
								<Header />
							</div>	
						)}
						{!headerImage && (
							<div className="col-11 col-xl-10">
								<Header />
							</div>	
						)}					
					</div>

					<div className="page-content">
						{children}
					</div>
				</div>
			</div>

			<PageFooter />
		</>
	);
};

export default LayoutPage;
