import React from "react";
import PropTypes from "prop-types";

const Hyperlink = (props) => {
	const { label, onClick, className, style, children, ariaCurrent, dataBsToggle, dataBsTarget } = props;

	// console.log("dddd", props);
	if (!label && !children) return null;
	if (!onClick) return null;

	const basicStyle = { cursor: "pointer", ...style };

	var opts = {};
	if (ariaCurrent) {
		opts['aria-current'] = ariaCurrent;		
	}
	if (dataBsToggle) {
		opts['data-bs-toggle'] = dataBsToggle;
    }
	if (dataBsTarget) {
		opts['data-bs-target'] = dataBsTarget;
	}

	return (
		<a onClick={onClick} className={className} style={basicStyle} {...opts}>
			{label || children}
		</a>
	);
};

export default Hyperlink;
Hyperlink.displayName = "Hyperlink";
Hyperlink.propTypes = {
	className: PropTypes.string,
	disabled: PropTypes.bool,
	label: PropTypes.any,
	onClick: PropTypes.func.isRequired,
	style: PropTypes.object,
};
