import React, { useState, useEffect } from "react";
import _ from "lodash";
import * as regExConstants from "@library/common/constants/regEx";

import {
	TextBox,
	TextArea,
	DropDownTypeWebService,
	Button,
} from "siteComponents";

// const outputVar = (v) => {
// 	if (v === undefined) return "undefined";
// 	if (v === "") return "empty string";
// 	return v;
// };

const validateEmail = (v) =>
	v !== undefined && v !== "" && regExConstants.misc.email.test(v);

const generateErrorMsg = (m) => <div className="alert alert-danger">{m}</div>;

const FormTemplate = (props) => {
	const {
		defaultErrorMessage = generateErrorMsg("Please correct the errors above"),
		feedbackMessage,
	} = props;

	const [showErrors, setShowErrors] = useState(false);
	const [formErrorMessage, setFormErrorMessage] = useState(undefined);
	const [formIsValid, setFormIsValid] = useState(false);

	const [componentErrors, setComponentErrors] = useState({});

	// ***************************************************
	// ** COMPONENT DATA
	// ***************************************************
	const componentData = {
		formQueryType: {
			className: ["col-12 col-md-6"],
			id: "formQueryType",
			label: "What is the nature of your enquiry?",
			onAutofill: props.setFormQueryType,
			component: (
				<DropDownTypeWebService
					value={props.formQueryType}
					onChange={props.setFormQueryType}
					id="formQueryType"
					DataFunction={props.fnGetContactLists}
				/>
			),
		},
		forceBreak: {
			id: "forcebreak",
			component: <div className="w-100"></div>,
			renderContainer: false,
		},
		formDescription: {
			className: ["col-12"],
			id: "formDescription",
			label: "Please provide a brief outline",
			onAutofill: props.setFormDescription,

			component: (
				<TextArea
					style={{ resize: "none" }}
					value={props.formDescription}
					onChange={props.setFormDescription}
					id="formDescription"
					className="form-control"
					maxLength="1000"
					rows="5"
				/>
			),
		},

		formName: {
			className: ["col-12", "col-md-6"],
			id: "formName",
			label: "Your name",
			onAutofill: props.setFormName,

			component: (
				<TextBox
					value={props.formName}
					onChange={props.setFormName}
					id="formName"
					className="form-control"
					type="text"
				/>
			),
		},
		formEmail: {
			className: ["col-12", "col-md-6"],
			id: "formEmail",
			label: "Your email",
			onAutofill: props.setFormEmail,

			component: (
				<TextBox
					value={props.formEmail}
					onChange={props.setFormEmail}
					id="formEmail"
					className="form-control"
					type="email"
				/>
			),
		},
	};

	// ***************************************************
	// ** DEBUG DATA
	// ***************************************************
	if (true) {
		console.groupCollapsed("FormTemplate");

		console.groupCollapsed("props");
		[
			"formQueryType",
			"formDescription",
			"formName",
			"formEmail",
		].forEach((k) => console.log(k, ":", props[k]));
		console.groupEnd();

		console.groupCollapsed("componentData");
		Object.entries(componentData).forEach(([k, v]) => console.log(k, ":", v));
		console.groupEnd();

		console.groupCollapsed("componentErrors");
		Object.entries(componentErrors).forEach(([k, v]) => console.log(k, ":", v));
		console.groupEnd();

		["formIsValid", "showErrors", "formErrorMessage"].forEach((k) =>
			console.log(k, ":", eval(k))
		);
		console.groupEnd();
	}

	// ***************************************************
	// ** VALIDATE
	// ***************************************************
	const fnValidate = () => {
		// Check for missing values
		const statusData = (function () {
			const _newComponentErrors = Object.fromEntries(
				Object.keys(componentData).map((k) => [k, true])
			);

			const composeRetItem = (isValid, formFeedback) => ({
				isValid,
				formFeedback,
				newComponentErrors: _newComponentErrors,
			});

			let _isValid = true;
			let _formFeedback = undefined;

			// Default missing check
			[
				"formQueryType",
				"formDescription",
				"formName",
				"formEmail",
			].forEach((k) => {
				if (!props[k]) {
					console.log("found missing error:", k);
					_newComponentErrors[k] = false;
					_isValid = false;
					_formFeedback = defaultErrorMessage;
				}
			});

			if (!_isValid) return composeRetItem(_isValid, _formFeedback);

			if (!validateEmail(props.formEmail)) {
				_formFeedback = generateErrorMsg("Please enter a valid email");
				_newComponentErrors.formEmail = false;
				_isValid = false;
				return composeRetItem(_isValid, _formFeedback);
			}

			return composeRetItem(_isValid, _formFeedback);
		})();

		//tidy up
		// console.log("dddd", "statusData", statusData);
		setComponentErrors(statusData.newComponentErrors);
		setFormErrorMessage(statusData.formFeedback);
		setFormIsValid(statusData.isValid);
	};

	// ***************************************************
	// ** EFFECT for CHANGES
	// ***************************************************
	useEffect(() => {
		fnValidate();
	}, [
		props.formQueryType,
		props.formDescription,
		props.formEmail,
		props.formName,
	]);

	// ***************************************************
	// ** AUTOFILL
	// ***************************************************

	useEffect(() => {
		document.addEventListener("onautocomplete", function (e) {
			const _target = e.target;

			console.log("autofill detected:", _target.id, _target.value);
			const _curItem = Object.values(componentData).find(
				(x) => x.id === _target.id
			);
			if (!_curItem) return;

			_curItem.onAutofill(_target.value);
		});
	}, []);

	// ***************************************************
	// ** MAIN RETURN
	// ***************************************************

	return (
		<form autoComplete="off">
			<div className="row mb-3">
				
				{[
					componentData.formQueryType,
					componentData.forceBreak,
					componentData.formDescription,
					componentData.formName,
					componentData.formEmail,
				].map(
					({
						id,
						label,
						component,
						className: baseClass,
						renderContainer = true,
					}) => {
						const showError = showErrors && componentErrors[id] === false;

						if (!renderContainer) return <span key={id}>{component}</span>;

						const _className = [
							...baseClass,
							"mb-3",
							"fb-form-group",
							showError ? "has-error" : "",
						]
							.filter((x) => x)
							.join(" ");

						return (
							<div key={id} className={_className}>
								<label htmlFor={id} className="form-label">
									{label}
								</label>
								{component}
								{/* {showErrors && componentErrors[id] === false && <p>Error</p>} */}
							</div>
						);
					}
				)}
			</div>
			<div className="row">
				{/* SUBMIT BUTTON */}
				<div className="col-12">
					<button
						className="btn btn-primary"
						onClick={(e) => {
							e.preventDefault();
							setShowErrors(true);
							if (!formIsValid) return;
							props.fnSubmit();
						}}
					>
						Send your query
					</button>
				</div>

				{showErrors && formErrorMessage && (
					<div className="col-12 mt-3">{formErrorMessage}</div>
				)}

				{feedbackMessage && (
					<div className="col-12 mt-3">{feedbackMessage}</div>
				)}
			</div>
		</form>
	);
};

export default FormTemplate;
