const globalDebug = false;

const requiredArgs = ["isDev", "google", "aws"];

export default (args) => {
	requiredArgs.forEach((x) => {
		if (!x in args) {
			console.log("args:", args);
			throw `Error in base config -- missing ${x}`;
		}
	});

	console.log("config args:", args);

	const isDev = args.isDev || false;
	const isTest = args.isTest || false;
	const isStage = args.isStage || false;
	const isProd = args.isProd || false;

	const liveDisable = (preferedValue) => (!isDev ? false : preferedValue);

	return {
		endPoints: require("./endpoints").default({
			AWSConfig: args.AWSConfig,
			AxiosData: args.AxiosData,
		}),
		isDev,
		isTest,
		isStage,
		isProd,
		google: args.google,
		aws: args.aws,
		links: {
		},

		debug: {
			clearConsoleOnRefresh: isDev || isTest,
		},
	};
};
