import React from "react";

import brandImage from "siteAssets/images/logos/FG.svg";
import drops from "siteAssets/images/logos/Drops.svg";
import config from "config";

import { SiteNav } from "siteComponents";

const PageNav = (props) => {

    const { menuData } = props;

    return (
        <SiteNav
            menuData={menuData}
            showScreenFinder={config.isDev}
            brandCol={8}
            brandColExpanded={4}
            brandImage={brandImage}
            brandImageAlt="Freedom Group"
            secondaryImage={drops}
            secomdaryImageAlt=""
        />
    );    
}

export default PageNav;