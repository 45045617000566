import React from "react";

import { NavLink, ExternalLink } from "siteComponents";

const SiteFooter = (props) => {

	const { menuData, socialMedia, brandImage, legalFooterText } = props; 

	return (
		<footer className="footer mt-auto py-5">
			<div className="container-fluid">
				<div className="row">
					<div className="col-12 col-lg-5 col-xl-5 footer-links pb-3">
						<div className="row">
							<div className="col-lg-2 col-xl-5 d-none d-lg-block"></div>
							<div className="col-12 col-lg-10 col-xl-7 text-center text-lg-start">
								<nav className="nav flex-column">
									{menuData.map((x, i) => {
										return (
											<NavLink
												key={x.route}
												label={x.label}
												routepath={x.route}
											/>
										);
									})}
									<div className="social-media-links">
										{socialMedia.map((x, i) => {
											return (
												<ExternalLink
													key={x.icon}
													href={x.link}
													label={
														(
															<span>
																<i className={`bi bi-${x.icon}`}></i>
																<span className="visually-hidden">{x.label}</span>
															</span>
														)
													}
													title={x.label}
													className="social btn btn-link-outline"
												/>
											);
										})}
									</div>
								</nav>
							</div>
						</div>
					</div>
					<div className="col-12 col-lg-6 col-xl-6 legal-footer text-center text-lg-start py-3">
						{brandImage}
						{legalFooterText}
					</div>
				</div>
			</div>
		</footer>
	);
};

export default SiteFooter;
