import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

import { ImageCapCard, Carousel } from "siteComponents";

const assets = require("siteAssets/pageManager").default("about-us");

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const AboutUs = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>

			<LayoutPageTemplateSection>

				<div className="row justify-content-md-center">

					<div className="col-12 col-md-10 col-lg-10 col-xl-8">
						<Carousel carouselId="aboutUsCarousel"
							className="aboutus-carousel"
							controlPosition={3}
							slides={(
								assets.carouselTiles.map((x, i) => {
									return (
										<div className="d-flex justify-content-center">
											<ImageCapCard
												key={i}
												className="carousel-card"
												fullHeight={false}
												route={x.route}
												imageUrl={x.imgUrl}
												title={x.title}
												showLink={true}
												linkButtonLabel="Find out more"
												bodyContent={(
													<div className="card-text">
														{x.text}
													</div>
												)}
											/>
										</div>
									);
								})
							)}
						/>
					</div>
				</div>

			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default AboutUs;
