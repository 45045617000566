import React, { useMemo } from "react";

import HyperlinkOrig from "libraryComponentsForm/Hyperlink";
import { useNavigate } from "react-router-dom";

const HyperlinkGoto = (props) => {

	const navigate = useNavigate();

	const { className = "", route, ...otherProps } = props;

	const finalProps = useMemo(() => {
		let retObj = {
			className: ["freedom-hyperlink", className]
				.filter((x) => x !== undefined && x !== "")
				.join(" "),
		};
		if (route) retObj.onClick = () => navigate(props.route);

		return retObj;
	}, [route, className]);

	return <HyperlinkOrig {...otherProps} {...finalProps} />;
};

export default HyperlinkGoto;