import React from "react";

import "siteAssets/css/layout";


const LayoutSite = (props) => {
	const { children } = props;

	return (
		<>
			<main className="layout-main flex-shrink-0 d-flex flex-column min-vh-100">
				{children}
			</main>
		</>
	);
};

export default LayoutSite;
