import React, { useCallback, useEffect, useState } from "react";

import siteFeeds from "siteManagers/feed";
import FeedViewer from "siteManagers/feed/Viewer";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("careers");
import companyManager from "siteManagers/companies";

const companyList = companyManager.getCompanyData([
	"pukka",
	"action",
	"freedomBrokers",
]);

const CompanyLogoHeader = () => {
	return (
		<div className="row">
			{companyList
				.map((x) => x.logoPath)
				.map((src, i) => (
					<div className="col-12 col-md-4 text-center py-5" key={i}>
						<img src={src} className="img-fluid max-h-5rem" />
					</div>
				))}
		</div>
	);
};

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerText={assets.headerText}>
	</LayoutPageTemplateHeaderImg>
);

const CareerView = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage}>
			<LayoutPageTemplateSection
				headerText="Our vision is to create a diverse workforce and an inclusive culture"
			>
				<p>
					Our vision is to create a diverse workforce and an inclusive culture which
					values individuality to ensure everyone can live healthier, be happy and
					enjoy fulfilled careers, ensuring positive business benefits and financial
					outcomes that can be shared amongst us all. We embrace diversity. As a
					fast paced, quick thinking growing entrepreneurial businesses, it is vital
					that we attract a diverse workforce. We believe that diversity brings out
					of the box thinking, a broad range of skills and experience which in turn
					creates a resilient and collaborative workforce.
				</p>
				<p>
					As an employee of the Freedom Group, you will find an inclusive culture,
					one where everyone feels valued and respected. We aim to provide everyone
					with a workplace that offers parity and equal opportunities through a
					range of employee engagement initiatives such as volunteering with grass
					roots organisations, a fair and robust appraisal process and a focus on
					employee wellbeing.
				</p>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="Job opportunities with the Freedom Group"
			>
				<CompanyLogoHeader />

				<FeedViewer
					func={siteFeeds.getFeed("career", {
						useDisplayComponent: true,
						timeStampKey: "timeStamp",
					})}
					MissingComponent={() => (
						<div className="alert alert-info">Sorry, there are currently no vacancies.</div>
					)}
					LoadingComponent={() => (
						<div className="alert alert-info">Checking for vacancies...</div>
					)}
				/>

			</LayoutPageTemplateSection>

		</LayoutPage>
	);
};

export default CareerView;
