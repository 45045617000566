import React from "react";

import brandImage from "siteAssets/images/logos/FG.svg";
import footerMenuData from "../../App/Routes/footerMenuData";
import socialMediaData from "../../App/Routes/socialMediaData";

import { SiteFooter } from "siteComponents";

const PageFooter = (props) => {

    return (
        <SiteFooter
            menuData={footerMenuData}
            brandImage={(
                <div className="text-center text-lg-start">
                    <img src={brandImage} alt="Freedom Group" className="img-fluid brand-img" />
                </div>
            )}
            socialMedia={socialMediaData}
            legalFooterText={
                <span className="small">
                    Freedom Services Group Ltd, Registered in England and Wales no 10688089. Registered Address - Bizspace, Cheadle Place, Cheadle, Cheshire, SK8 2JX.
                </span>
            }
        />
    );
}

export default PageFooter;