import React, { useCallback } from "react";
import moment from "moment";

import { BaseCard, Body, Title } from "siteComponents";

const DisplayComponent = (props) => {
	const { className, } = props;

	return null;

	const _className = [
		"row",
		"text-center",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className={_className}>
			<div className="col-12 col-lg-4">
				Section 1<br />
				Content TBC
			</div>
			<div className="col-12 col-lg-4">
				Section 2<br />
				Content TBC
			</div>
			<div className="col-12 col-lg-4">
				Section 3<br />
				Content TBC
			</div>
		</div>
	);
};

export default DisplayComponent;
