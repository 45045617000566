import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { BaseCard, ImageTop, Body, Title, HyperlinkGoto } from "siteComponents";

const DisplayComponent = (props) => {
	const { title, bodyContent, route, className, imageUrl, imageAltText, fullHeight = true, showLink = false, linkButtonLabel = "" } = props;

	const navigate = useNavigate();

	const _className = [
		"tile",
		"text-center",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<BaseCard
			cardClasses={_className}
			fullHeight={fullHeight}
			cardOnClick={() => navigate(route)}
		>
			<ImageTop imageUrl={imageUrl} imageAltText={imageAltText} />
			<Body titleContent={(<Title content={title} centerTitle={false} />)}>
				{bodyContent}
				{showLink && route && (
					<div className="mt-3">
						<HyperlinkGoto route={route} label={linkButtonLabel} />
					</div>
				)}
			</Body>
		</BaseCard>
	);
};

export default DisplayComponent;
