import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

import ValueCard from "./ValueCard";
const assets = require("siteAssets/pageManager").default("about-us-our-values");
import {  } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const OurValues = (props) => {
	// const { children } = props;

	console.log(assets);

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>
			<LayoutPageTemplateSection>
				<p className="pb-4">
					Our values are our guiding light. They are the set of standards that sum up what we stand for,
					and that lead the way we do business. These beliefs influence and shape our working environment,
					help our employees understand the behaviours expected of them, and drive the business decisions
					our leadership teams make.
				</p>

				{assets.ourValues && (
					<div className="row row-cols-1 row-cols-md-2 row-cols-xl-4">
						{assets.ourValues.map((val, i) => {
							return (
								<ValueCard key={i} title={val.title} items={val.items} />
							);
						})}
					</div>
				)}
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default OurValues;
