import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import TagManager from "react-gtm-module";
import config from "config";

const dataLayerName = "dataLayer";

if (config.google && config.google.gtmId) {
	TagManager.initialize({
		gtmId: config.google.gtmId,
		dataLayerName,
	});
}

const GoogleAnalytics = (props) => {
	if (!config.google || !config.google.gtmId) {
		// throw `Error -- missing config.google.gtmId`;
		return <>{props.children}</>;
	}
	// must be in the router!
	const { pathname } = useLocation();

	useEffect(() => {
		const tagManagerArgs = {
			dataLayer: {
				// userId: "001",
				// userProject: "project",
				page: pathname,
			},
			dataLayerName,
		};
		TagManager.dataLayer(tagManagerArgs);

		console.log(
			"GoogleAnalytics:",
			config.google.gtmId,
			tagManagerArgs,
			pathname,
			window.dataLayer
		);
		//	ReactGA.pageview(pathname);
	}, [pathname]);

	return <>{props.children}</>;
};

export default GoogleAnalytics;
