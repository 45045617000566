import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("about-us-innovation");
import { EmailLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const Innovation = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>
			<LayoutPageTemplateSection
				headerText="Driving innovation to make a difference"
			>
				<p>
					Innovation is central to what we do at Freedom. It is one of our core values that we work
					and live by and shapes the decisions we make. We're not afraid of change and are continuously
					looking for ways that we can use technology to meet growing customer needs.
				</p>

				<p>
					We are building a strong foundation in which to grow and embed ourselves as a leading insurtech company.
				</p>
			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="How will we do this?"
			>
				<h3>
					Data and MI
				</h3>
				<p>
					By looking at new ways to collect and analyse data, our brands can start to better understand the behaviour
					of customers, and what they need. This allows us to provide more personalised insurance products,
					competitive pricing, and a tailored customer service. It can also help us to manage risk, identify fraud,
					improve driver safety, and make better decisions as a company.
				</p>

				<h3>
					Automation and technology
				</h3>
				<p>
					Technological advancements offer the insurance industry a huge opportunity to meet growing customer needs
					and regulatory demands. This can be from anticipating customer queries, streamlining purchase and claims
					processes, and sending tailored personalised communications to customers designed to educate and engage.
				</p>
				<p>
					We're committed to driving these efficiencies now and in the future.
				</p>
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default Innovation;
