import React from "react";

const Header = (props) => {

    const { children, isCentered = true, className } = props;

    const _classes = [
        "card-header",
        isCentered ? "text-center" : "",
        "text-grey",
        className
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <div className={_classes}>
            <h3 className="fw-bold fs-5 mt-2">{children}</h3>
        </div>
    );

}

export default Header;