import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("news-media");
import { EmailLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerText={assets.headerText}>
	</LayoutPageTemplateHeaderImg>
);

const NewsAndMedia = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage}>
			<LayoutPageTemplateSection>
				<p>
					Whatever the idea, opportunity or feedback, we would love to hear from you.
					
				</p>
				<p>
					If your enquiry is related to either Freedom Innovate, Pukka Insure or Freedom
					Brokers, please contact them directly as unfortunately we cannot assist with
					those matters.
				</p>
				<p>
					Alternatively, please <EmailLink emailAddress="webenquiries@freedomservicesgroup.co.uk" altLabel="send us an email" nowrap={false} />.
				</p>
			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="Just say 'Hey!'"
			>

			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default NewsAndMedia;
