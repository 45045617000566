import React, { useCallback, useEffect, useState } from "react";

const FeedViewer = (props) => {
	const {
		func,
		LoadingComponent = () => null,
		MissingComponent = () => null,
		ErrorComponent = () => null,
	} = props;

	if (!func) throw `Error in feed viewer -- missing "func"`;

	const [dataFeed, setDatafeed] = useState();
	const [isInit, setIsInit] = useState(false);
	const [isError, setIsError] = useState(false);

	useEffect(() => {
		func
			.then((result) => {
				if (!result) {
					setIsError(true);
					setIsInit(true);
					return;
				}
				setDatafeed(result);
				setIsInit(true);
			})
			.catch(() => {
				setIsError(true);
				setIsInit(true);
			});
	}, []);

	if (!isInit) return <LoadingComponent />;

	if (isError) return <ErrorComponent />;

	const noData = dataFeed.length === 0;

	if (noData) return <MissingComponent />;

	return <>{dataFeed}</>;
};

export default FeedViewer;
