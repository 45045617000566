import React from "react";

const TitleTwoColumns = (props) => {

    const { leftSide, lsClass, rightSide, rsClass = "text-end" } = props;

    const _lsClass = [
        "col-8",
        lsClass,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    const _rsClass = [
        "col-4",
        rsClass,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <div className="row card-title">
            <div className={_lsClass}>
                {leftSide}
            </div>
            <div className={_rsClass}>
                {rightSide}
            </div>
        </div>
    );

}

export default TitleTwoColumns;