import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Route, Routes, Navigate } from "react-router-dom";
import GoogleAnalytics from "../data/GoogleAnalytics";
// import routeDataOracle from "./routeDataOracle";

// export it, for the siteMap etc.
export const routeData = require("./routeData").default;

const SiteRoutes = (props) => {
	return (
		<GoogleAnalytics>
			<Routes>
				//** Main routes */
				{routeData
					.filter((x) => x.component && x.route)
					.map((x, i) => (
						<Route
							path={x.route}
							exact={true}
							key={`main_${i}`}
							element={<x.component />}
						/>
					))}
				//** default redirect */
				<Route path="*" element={<Navigate to="/" />} />
			</Routes>{" "}
		</GoogleAnalytics>
	);
};

SiteRoutes.propTypes = {
	history: PropTypes.any,
	name: PropTypes.string,
};
SiteRoutes.defaultProps = { name: "Routes" };
export default SiteRoutes;
