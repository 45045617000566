import React from "react";

const pageManagerData = {
	["landing"]: {
		headerCardText:
			"A people oriented and innovative group of brands providing insurance services to the UK car and van market.",
		headerImage: require("./images/pageHeader/landing-blur.png"),
		className: "home",
	},

	/* ABOUT US */
	["about-us"]: {
		headerCardText:
			"Find out a bit more about who we are, what we do, and what we stand for",
		headerImage: require("./images/pageHeader/about-us.png"),
		carouselTiles: [
			{
				title: "Our company",
				route: "/about-us/our-company",
				imgUrl: require("./images/carouselSlide/about-us-our-company.png"),
				text: "More about what we do at Freedom",
			},
			{
				title: "Our story",
				route: "/about-us/our-story",
				imgUrl: require("./images/carouselSlide/about-us-our-journey.png"),
				text: "We've come a long way",
			},
			//{
			//    title: "Our team",
			//    route: "/about-us/our-team",
			//    imgUrl: require("./images/carouselSlide/about-us-our-team.png"),
			//    text: "Who are the dream team driving us forward",
			//},
			{
				title: "Our values",
				route: "/about-us/our-values",
				imgUrl: require("./images/carouselSlide/about-us-our-values.png"),
				text: "What holds us all together",
			},
			{
				title: "Innovation",
				route: "/about-us/innovation",
				imgUrl: require("./images/carouselSlide/about-us-innovation.png"),
				text: "Innovation is at the heart of everything we do at Freedom",
			},
			{
				title: "Women in finance",
				route: "/about-us/women-in-finance",
				imgUrl: require("./images/carouselSlide/about-us-women-in-finance.webp"),
				text: "Helping to build a more balanced industry",
			},
		],
	},
	["women-in-finance"]: {
		headerText: "Women in finance",
		headerImage: require("./images/pageHeader/about-us-women-in-finance.webp"),
	},
	["about-us-innovation"]: {
		headerText: "How we're innovative",
		headerImage: require("./images/pageHeader/about-us-innovation.png"),
	},
	["about-us-our-company"]: {
		headerCardText:
			"Find out a bit more about the brands that make up Freedom Services Group",
		headerImage: require("./images/pageHeader/about-us-our-company.png"),
	},
	["about-us-our-story"]: {
		headerCardText:
			"We love a good journey... here's how we've got to where we are.",
		headerImage: require("./images/pageHeader/about-us-our-journey.png"),
		className: "our-story",
	},
	["about-us-our-team"]: {
		headerText:
			"Our executive team have an average of over 20 years in the insurance industry, so they definitely know their stuff",
		headerImage: require("./images/pageHeader/about-us-our-team.png"),
	},
	["about-us-our-values"]: {
		headerCardText: "Our values are more than just words. They're who we are.",
		headerImage: require("./images/pageHeader/about-us-our-values.png"),
		ourValues: [
			{
				title: "Innovative",
				items: [
					"Continuous improvement",
					"Technology focused",
					"Adaptable to change",
					"Forward thinking",
				],
			},
			{
				title: "Authentic",
				items: [
					"Open",
					"Honest",
					"Transparent",
					"Consistently doing what we promise",
				],
			},
			{
				title: "Respectful",
				items: [
					"Engage and enable",
					"Inspire confidence",
					"Considerate of others",
					"Respectful of ourselves",
				],
			},
			{
				title: "Protective",
				items: [
					"Compassionate and caring",
					"Treat everyone fairly",
					"Do the right thing",
				],
			},
		],
	},

	/* CAREERS */
	["careers"]: {
		headerImage: require("./images/pageHeader/careers.png"),
	},
	["careers-vacancies"]: {
		headerImage: require("./images/pageHeader/careers-current-vacancies.png"),
	},
	["careers-diversity"]: {
		headerImage: require("./images/pageHeader/careers-diversity-inclusion.png"),
	},
	["careers-life"]: {
		headerImage: require("./images/pageHeader/careers-freedom-life.png"),
	},
	["careers-why"]: {
		headerImage: require("./images/pageHeader/careers-why-work-for-us.png"),
	},

	/* CONTACT US */
	["contact-us"]: {
		headerImage: require("./images/pageHeader/contact-us.png"),
		headerCardText:
			"If you want to get in touch with us, just send us an enquiry below.",
	},
	["contact-us-careers"]: {
		headerImage: require("./images/pageHeader/contact-us-careers-enquiry.png"),
	},
	["contact-us-press"]: {
		headerImage: require("./images/pageHeader/contact-us-press-enquiry.png"),
	},

	/* GIVING BACK */
	["giving-back"]: {},
	["giving-back-communities"]: {},
	["giving-back-customers"]: {},
	["giving-back-employees"]: {},
	["giving-back-environment"]: {},
	["giving-back-approach"]: {},
	["giving-back-partners"]: {},

	/* NEWS AND MEDIA */
	["news-media"]: {
		headerImage: require("./images/pageHeader/news-media.png"),
	},
	["news-media-blog"]: {
		headerImage: require("./images/pageHeader/news-media-blog.png"),
	},
	["news-media-latest"]: {
		headerImage: require("./images/pageHeader/news-media-latest.png"),
	},
	["news-media-podcast"]: {},
};

export default pageManagerData;
