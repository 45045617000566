import React from "react";

import doingRight from "siteAssets/images/logos/doing-right-by-you.svg";

const LayoutPageTemplateHeader = (props) => {
	const { headerText, children } = props;
	return (
		<>
			<div className="page-intro-content">
				<h1>{headerText}</h1>
				{children}
			</div>
			<div className="text-end doing-right">
				<img className="" src={doingRight} alt="Doing Right By You" />
			</div>
		</>
	);
};

export default LayoutPageTemplateHeader;
