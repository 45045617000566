import config from "config";
import React from "react";

const menuData = [
	{
		route: "/",
		label: <span>Home</span>,
		className: "nav-home",
	},
	{
		route: "/about-us",
		label: <span>About us</span>,
		items: [
			{ route: "/about-us/our-company", label: <span>Our company</span> },
			{ route: "/about-us/our-story", label: <span>Our story</span> },
			//{ route: "/about-us/our-team", label: <span>Our team</span> },
			{ route: "/about-us/our-values", label: <span>Our values</span> },
			{ route: "/about-us/innovation", label: <span>Innovation</span> },
		]
	},

	/*
	{
		route: "/giving-back",
		label: <span>Giving Back</span>,
		items: [
			{ route: "/giving-back/our-approach", label: <span>Our approach</span> },
			{ route: "/giving-back/customers", label: <span>Customers</span> },
			{ route: "/giving-back/employees", label: <span>Employees</span> },
			{ route: "/giving-back/communities", label: <span>Communities</span> },
			{ route: "/giving-back/partners", label: <span>Partners</span> },
			{ route: "/giving-back/environment", label: <span>Environment</span> },
		]
	},
	{
		route: "/news-and-media",
		label: <span>News &amp; Media</span>,
		items: [
			{ route: "/news-and-media/latest-news", label: <span>Latest news</span> },
			{ route: "/news-and-media/blog", label: <span>Blog</span> },
			{ route: "/news-and-media/podcast", label: <span>Podcast</span> },
		]
	},
	{
		route: "/careers",
		label: <span>Careers</span>,
		items: [
			{ route: "/careers/why-work-for-us", label: <span>Why work for us</span> },
			{ route: "/careers/freedom-life", label: <span>Freedom life</span> },
			{ route: "/careers/diversity-and-inclusion", label: <span>Diversity &amp; Inclusion</span> },
			{ route: "/careers/current-vacancies", label: <span>Current vacancies</span> },
		]
	},
	*/
	{
		route: "/contact-us",
		label: <span>Contact Us</span>,
	},
];

export default menuData;
