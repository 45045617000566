import React from "react";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";

import "core-js/stable";
import "regenerator-runtime/runtime";

import "bootstrap/dist/css/bootstrap.css";
import "siteAssets/css/base";
import "siteAssets/css/utility";
import "siteAssets/css/layout";
import "siteAssets/css/components";

import SiteRoutes from "./Routes";
import ScrollToTop from "./Routes/ScrollToTop";
import LayoutSite from "siteLayouts/Site";
// import RouteLogger from "./data/RouteLogger";

// import GoogleAnalytics from "./data/GoogleAnalytics";

const App = (
	<LayoutSite>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Freedom Services Group</title>
		</Helmet>
		<React.StrictMode>
				<BrowserRouter>
					<ScrollToTop>
						<SiteRoutes />
					</ScrollToTop>
				</BrowserRouter>
		</React.StrictMode>
	</LayoutSite>
);

export default App;
