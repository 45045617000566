import React from "react";

const BaseCard = (props) => {
	const {
		children,

		// Card styling
		hasShadow,
		cardHighlight = false,
		addBottomMargin = true,
		fullHeight = false,
		cardClasses = "",
		cardOnClick,
	} = props;

	const _classes = [
		"card",
		cardHighlight ? "card-highlight" : "",
		addBottomMargin ? "mb-3" : "",
		fullHeight ? "h-100" : "",
		hasShadow ? "card-shadow" : "",
		cardOnClick ? "clickable" : "",
		cardClasses,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className={_classes} onClick={cardOnClick}>
			{children}
		</div>
	);
};

export default BaseCard;
