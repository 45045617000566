import React from "react";

import { HyperlinkGoto, NavLink, NavWithSubMenu, ScreenFinder } from "siteComponents";

const SiteNav = (props) => {

    /*
     * https://getbootstrap.com/docs/5.0/components/navbar/
    */

    const {
        menuData,
        showScreenFinder,
        brandCol = 3,
        brandColExpanded = 3,
        brandImage, brandImageAlt,
        secondaryImage, secondaryImageAlt
    } = props;

    const itemsCol = 12 - brandCol;
    const itemsColExpanded = 12 - brandColExpanded;

    const switchBreakpoint = "lg";

    const navbarId = "headerNavBarContent";
    const navbarTarget = `#${navbarId}`;
    const navbarTargetCloseOnly = `#${navbarId}.show`;
    const navbarToggle = "collapse";

    return (
        <div className="container-fluid fsg-nav sticky-top">
            <div className="row">
                <nav className={`navbar navbar-expand-${switchBreakpoint} justify-content-${switchBreakpoint}-end navbar-dark site-header-nav`}>
                    <div className="container-fluid px-0">
                        <div className={`col-${brandCol} col-${switchBreakpoint}-${brandColExpanded} brand-img`}>
                            <HyperlinkGoto route="/" className="navbar-brand" dataBsToggle={navbarToggle} dataBsTarget={navbarTargetCloseOnly}>
                                <img src={brandImage} alt={brandImageAlt} className="img-fluid" />                                
                            </HyperlinkGoto>
                            <ScreenFinder showMe={showScreenFinder} />
                        </div>

                        <div className={`col-${itemsCol} col-${switchBreakpoint}-${itemsColExpanded} d-flex justify-content-end`}>

                            <button className="navbar-toggler" type="button" data-bs-toggle={navbarToggle} data-bs-target={navbarTarget} aria-controls={navbarId} aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                                <span className="navbar-closeme">X</span>
                            </button>

                            <div
                                className={`freedom-nav collapse navbar-collapse justify-content-${switchBreakpoint}g-end flex-${switchBreakpoint}-grow-0`}
                                id={navbarId}
                            >
                                <ul className={`navbar-nav navbar-nav-scroll flex-row flex-wrap flex-${switchBreakpoint}-nowrap pt-3 pb-4 py-${switchBreakpoint}-0 text-end text-${switchBreakpoint}-center text-xl-end me-auto mb-2 mb-${switchBreakpoint}-0 gap-xl-3`}>

                                    {menuData.map((x, i) => {
                                        /*
                                        if (x.items) {
                                            return (
                                                <>
                                                    <NavWithSubMenu
                                                        key={x.route}
                                                        label={x.label}
                                                        route={x.route}
                                                        className={x.className}
                                                        items={x.items}
                                                        dataBsToggle={navbarToggle}
                                                        dataBsTarget={navbarTargetCloseOnly}
                                                        switchBreakpoint={switchBreakpoint}
                                                    />
                                                </>
                                            );
                                        }
                                        */

                                        const className = ["nav-item", x.className]
                                            .filter((x) => x)
                                            .join(" ");

                                        return (
                                            <li key={x.route} className={className}>
                                                <NavLink
                                                    label={x.label}
                                                    routepath={x.route}
                                                    dataBsToggle={navbarToggle}
                                                    dataBsTarget={navbarTargetCloseOnly}
                                                />
                                            </li>
                                        );
                                    })}
                                    
                                    <li className="nav-item nav-img-end">
                                        <img src={secondaryImage} alt={secondaryImageAlt} />
                                    </li>
                                </ul>
                            </div>

                        </div>
                    </div>
                </nav>
            </div>
        </div>
    );
}

export default SiteNav;