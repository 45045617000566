import React from "react";

const HorizontalCard = (props) => {

    const { imageUrl, imageAltText, className, breakpoint, children } = props;

    const _classes = [
        "row",
        "g-0",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <div className={_classes}>
            <div className="col-md-4">
                <img src={imageUrl} className="img-fluid rounded-start" alt={imageAltText} />
            </div>
            <div className="col-md-8">
                {children}
            </div>
        </div>
    );

}

export default HorizontalCard;