import React from "react";

import { PageHeaderCard } from "siteComponents";

const LayoutPageTemplateHeaderImg = (props) => {
	const { headerCardText, children } = props;
	return (
		<>
			<div className="page-intro-content d-flex align-items-start">
				<PageHeaderCard text={headerCardText} />
			</div>
		</>
	);
};

export default LayoutPageTemplateHeaderImg;
