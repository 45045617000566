import React from "react";

const ImageTop = (props) => {

    const { imageUrl, imageAltText, className } = props;

    const _classes = [
        "card-img-top",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <img src={imageUrl} className={_classes} alt={imageAltText} />
    );

}

export default ImageTop;