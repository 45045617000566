import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";
import companyManager from "siteManagers/companies";

const assets = require("siteAssets/pageManager").default("about-us-our-company");
import { CompanyItem } from "siteComponents";
import Sections from "../Sections";

const companyList = companyManager.getCompanyData([
	"freedomBrokers",
	"pukka",
	"action",
]);

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const OurCompany = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>

			<LayoutPageTemplateSection headerText="What makes us unique">
				<p>
					Since it began, Freedom Services Group has evolved into an insurance group
					focusing on private and commercial insurance.
				</p>
				<p>
					Our full range of services is what helps us to stand out which consist of:
				</p>

				<ul className="list-unstyled li-py-2">
					<li>
						<strong>A broker</strong>
						- selling policies to the public both directly and through aggregators.
					</li>
					<li>
						<strong>An MGA</strong>
						- we design our own insurance products and sell them through both our own broker,
						and a panel of third-party brokers.
					</li>
					<li>
						<strong>A claims administrator</strong>
						- we handle the claims process for Freedom insurance policy holders.
					</li>
				</ul>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="Our brands"
			>
				<div className="row mb-4">
					<div className="col-12 pb-5">
						We're so proud of our brands and the part they play within Freedom 
						Services. Each with a different role, they encompass the values of 
						Freedom completely and our shared purpose.
					</div>
				</div>

				<div className="row">
					{companyList.map((x) => (
						<div className="col-12 col-lg-4 pb-5" key={x.displayName}>
							<CompanyItem {...x} displayLink={true} />
						</div>
					))}
				</div>
			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection>
				<Sections />
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default OurCompany;
