import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeader from "siteLayouts/Page/templates/Header";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

import siteFeeds from "siteManagers/feed";
import FeedViewer from "siteManagers/feed/Viewer";

import imageUrl from "./assets/podcast-human-business.webp";

import { ExternalLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeader headerText="Podcast">

	</LayoutPageTemplateHeader>
);

const Podcast = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header}>
			<LayoutPageTemplateSection
				headerText="Human Business with Sam White"
			>
				<div className="row">
					<div className="col-12 col-lg-4">

						<div className="row">
							<div className="col-12">
								<img src={imageUrl} className="img-fluid rounded-start" alt="Human Business" />
							</div>
							<div className="col-12">
								Human Business brings together a collection of Sam's friends, business owners and
								colleagues to discuss how to succeed in business without ruining anybody's spirit
								along the way.
							</div>
						</div>
					</div>

					<div className="col-12 col-lg-7 pt-3">

						<h4 className="mb-3">Episodes</h4>

						<FeedViewer
							func={siteFeeds.getFeed("samPodcast", {
								useDisplayComponent: true,
								timeStampKey: "timeStamp",
							})}
							LoadingComponent={() => <p>Please wait...</p>}
						/>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						https://podcasts.apple.com/gb/podcast/human-business-with-sam-white/id1476366810
					{/* <iframe
						allow="autoplay *; encrypted-media *; fullscreen *"
						frameBorder="0"
						height="450"
						style="width:100%;max-width:660px;overflow:hidden;background:transparent;"
						sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
						src="https://embed.podcasts.apple.com/gb/podcast/human-business-with-sam-white/id1476366810"
					></iframe> */}
					</div>
				</div>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection>
				
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default Podcast;
