import React, { useEffect } from "react";
import { useLocation } from "react-router";

const ScrollToTop = (props) => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth', // Use 'instant' here if we want to jump straight to top of page
        });
    }, [location]);

    return <>{props.children}</>
};

export default ScrollToTop;