import React from "react";

const Footer = (props) => {

    const { children, className, isCentered = true, blendIn = true, clickAction } = props;

    const _classes = [
        "card-footer",
        isCentered ? "text-center px-xl-0" : "",
        blendIn ? "bg-transparent bt-0" : "",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    if (clickAction) {
        return (
            <div className={_classes} onClick={clickAction}>
                {children}
            </div>
        );
    }

    return (
        <div className={_classes}>
            {children}
        </div>
    );

}

export default Footer;