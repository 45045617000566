import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("contact-us");
import companyManager from "siteManagers/companies";
import ContactForm from "./ContactForm";
import siteServices from "siteManagers/services";

import { ExternalLink } from "siteComponents";

const fnGetContactLists = siteServices.callback.contactUs.getContactLists;
const fnSubmit = siteServices.callback.contactUs.sendContactInfo;
 //const fnSubmit = () =>
 //	new Promise((resolve, reject) => {
	//	  setTimeout(() => {
 //			const throwError = Math.random() < 0.5; // 50% chance
 //			if (throwError) reject();
 //			else resolve();
 //		}, 2000);
 //	});

const companyList = companyManager.getCompanyData([
	"pukka",
	"action",
	"freedomBrokers",
]);

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const ContactUs = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>

			<LayoutPageTemplateSection
				headerText="How can we help?"
			>
				<div className="row justify-content-between">
					<div className="col-12 col-xl-7">
						<p className="mb-4">
							Whether it's a careers enquiry, or press/media enquiry, get in touch and
							we'll answer it as soon as possible.
						</p>
						<ContactForm
							fnGetContactLists={fnGetContactLists}
							fnSubmit={fnSubmit}
						/>
					</div>
					<div className="col-12 col-xl-4 pt-4 pt-xl-0">
						<p className="">
							Or if you're looking to get in touch with one of our specific companies, click on their logo for more details.
						</p>
						<div className="row justify-content-center">
							{companyList.map((x) => (
								<div className="col-12 col-sm-4 col-xl-12 text-center py-4 company-item" key={x.displayName}>
									<ExternalLink
										href={x.urlContact}
										label={<img src={x.logoPath} className="" />}
									/>
								</div>
							))}
						</div>
					</div>
				</div>
				
			</LayoutPageTemplateSection>

		</LayoutPage>
	);
};

export default ContactUs;
