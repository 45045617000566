const baseArgs = {
	isProd: true,
	google: { gtmId: "" },
	aws: {
		endPoint:
			"https://4qbnjdxz37.execute-api.eu-west-1.amazonaws.com/freedom-live",
		apiKey: "jZvVUf4Mws6VUFXwTexUe6xn14T4bBVE1j2BbVmA",
	},
	AxiosData: {
		endPoint:
			"https://y9fblg5g36.execute-api.eu-west-1.amazonaws.com/freedom-live",
		apiKey: "q4KMFDfqYK3o37lKWm9sl9GjJXipEqB29zZBdkby",
	},
};
const baseConfig = require("../base").default(baseArgs);

export default baseConfig;
