import React from "react";

const HeaderTop = (props) => {

    const { children, className, isCentred = true } = props;

    const _classes = [
        "card-header",
        "breakout-top",
        className,
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    const _childClasses = [
        "bg-white",
        isCentred ? "mx-auto" : "",
        "width-fitcontent",
        "px-3",
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <div className={_classes}>
            <div className={_childClasses}>
                {children}
            </div>            
        </div>
    );

}

export default HeaderTop;