import React from "react";

import { BaseCard, Body, Header } from "siteComponents";

const DisplayComponent = (props) => {
	const { title, items, } = props;

	const _className = [
		"value-card",
		//"text-center",
		//className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className="col mb-3">
			<BaseCard
				cardClasses={_className}
				fullHeight={true}
			>
				<Header>{title}</Header>
				<Body>
					{items && (
						<ul>
							{items.map((v, i) => {
								return (
									<li key={i}>
										{v}
									</li>
								);
							})}
						</ul>
					)}
					
				</Body>
			</BaseCard>
		</div>
	);
};

export default DisplayComponent;