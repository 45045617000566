// import axios from "axios";
// import { createServiceObj } from "@library/common/helpers/service";
import postProcessAxiosFunctions from "@library/configs/site/configItem/postProcessAxiosFunctions";

const requiredArgs = ["AWSConfig", "AxiosData"];

const generateEndpoints = (args = {}) => {
	requiredArgs.forEach((x) => {
		if (!x in args) {
			console.log("args:", args);
			throw `Error in base config endPoints -- missing ${x}`;
		}
	});

	const { AxiosData, AWSConfig, dateHelpers } = args;

	return {		
		callback: {
			contactUs: {
				getContactLists: {
					postProcessAxios: [postProcessAxiosFunctions.convertLabels],
					retryCount: 0,
					axiosOptions: {
						method: "GET",
						headers: {
							"x-api-key": AxiosData.apiKey,
						},
						url: `${AxiosData.endPoint}/Contact/GetLists?listtype=g-querytype`,
					},
				},
				sendContactInfo: {
					retryCount: 0,
					axiosOptions: {
						method: "POST",
						headers: {
							"x-api-key": AxiosData.apiKey,
						},
						url: `${AxiosData.endPoint}/Contact/SendGroupRequest`,
					},
				},
			},
		},
	};
};

export default generateEndpoints;
