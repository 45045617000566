import React from "react";
import { useResolvedPath, useMatch } from "react-router-dom";

import { HyperlinkGoto } from "siteComponents";

const CheckCurrentPage = (routepath) => {

    let resolved = useResolvedPath(routepath);
    let isCurrentPage = useMatch({ path: resolved.pathname, end: true });

    if (isCurrentPage) {
        return { ariaCurrent: "page", isCurrent: true };
    }

    return { ariaCurrent: undefined, isCurrent: false };
}

const NavLink = (props) => {

    const { label, routepath, className, dataBsToggle, dataBsTarget } = props;

    const linkClassName = !className ? "nav-link" : className;

    var currentPage = CheckCurrentPage(routepath);
    const linkClass = `${linkClassName} ${currentPage.isCurrent ? "active" : ""}`;

    return (
        <HyperlinkGoto
            label={label}
            route={routepath}
            className={linkClass}
            ariaCurrent={currentPage.ariaValue}
            dataBsToggle={dataBsToggle}
            dataBsTarget={dataBsTarget}
        />
    );
}

export default NavLink;