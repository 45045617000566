// common components
import React, { useState, useCallback } from "react";
import _DatePickerTextBox from "@library/common/components/base/form/DatePicker/Textbox";
import _TextBox from "@library/common/components/base/form/TextBox";
import _ModalLoading from "@library/common/components/base/form/Modal/Loading";
import _ButtonOrig from "@library/common/components/base/form/Button";
import _DropDownType from "@library/common/components/base/form/DropDown/Type";

import PanelLoading from "../Panel/Loading";

const generateClass = (array = []) => array.filter((x) => x).join(" ");
const dropdownStyle = {
	container: (base) => ({
		...base,
		paddingLeft: 0,
		paddingRight: 0,
	}),
	groupHeading: (base) => ({
		...base,
		color: "green",
		fontWeight: "bold",
	}),
	menu: (base) => ({
		...base,
		zIndex: 20, // make sure it's on top of everything else
	}),
};

//export const Help = require("./Help").default;

export const Button = (props) => {
	const { className = "", classNameDisabled = "", ...otherProps } = props;

	const _className = ["btn", className].filter((x) => x).join(" ");

	const _classNameDisabled = [classNameDisabled].filter((x) => x).join(" ");

	return (
		<ButtonOrig
			className={_className}
			classNameDisabled={_classNameDisabled}
			{...otherProps}
		/>
	);
};

export const TextBox = (props) => {
	const { className, ...otherProps } = props;

	const _className = generateClass(["form-control", className]);

	return <_TextBox className={_className} {...otherProps} />;
};

export const TextArea = (props) => {
	const { value, onChange: _onChange, ...otherProps } = props;

	["value", "onChange"].forEach((k) => {
		if (!(k in props)) throw `Error in TextArea -- missing props:${K}`;
	});
	const onChange = (e) => {
		const v = e.target.value;
		_onChange(v);
	};
	return <textarea {...otherProps} defaultValue={value} onKeyUp={onChange} />;
};

export const DropDownType = (props) => {
	const { className = "", ...otherProps } = props;

	const _className = generateClass([className, "fb-select", "align-middle"]);

	return (
		<_DropDownType
			className={_className}
			classNamePrefix="dropdown-type"
			styles={dropdownStyle}
			isClearable
			{...otherProps}
		/>
	);
};

export const DropDownTypeWebService = (props) => {
	const { DataFunction, ...otherProps } = props;
	return <DropDownType {...otherProps} DataFunction={DataFunction} />;
};

export const DatePickerTextBox = (props) => {
	const { className, ...otherProps } = props;

	const _className = ["debug-library-datePicker-text", "row", className]
		.filter((x) => x)
		.join(" ");

	return (
		<div className={_className}>
			<_DatePickerTextBox
				{...otherProps}
				TextBox={TextBox}
				classNameDay="col-4 col-md-2 pe-md-0 pe-xl-3"
				classNameMonth="col-4 col-md-2 pe-md-0 pe-xl-3"
				classNameYear="col-4 col-md-3 col-xl-2"
			/>
		</div>
	);
};

export const ModalLoading = (props) => {
	const { children: headingText, ...otherProps } = props;

	const [currentMessage, setCurrentMessage] = useState();
	const messages = useCallback(
		[
			"Please wait a moment...",
			"Sorry, this is taking longer than expected. Please wait...",
		],
		[]
	);

	const className = [
		"modal-main",
		"p-0",
		"m-0",
		"col-10",
		"col-md-6",
		"col-lg-5",
		"bg-transparent",
	].join(" ");
	const overlayClassName = ["modal-overlay"].join(" ");

	return (
		<_ModalLoading
			className={className}
			overlayClassName={overlayClassName}
			onMessageChange={(m) => setCurrentMessage(m)}
			cycleDelay={15000}
			cycleLoop={false}
			messages={messages}
			{...otherProps}
		>
			<PanelLoading header={headingText}>{currentMessage}</PanelLoading>
		</_ModalLoading>
	);
};

export const ModalLoadingOpened = (props) => (
	<ModalLoading {...props} isOpen={true} />
);
