import config from "config";
import React from "react";

const socialMedia = [
	//{
	//	link: "https://www.youtube.com/channel/UC9A6-b58D_qWp2i7O3rzRrg",
	//	label: "Visit us on YouTube",
	//	icon: "youtube",
	//},
	//{
	//	link: "https://twitter.com/FreedomService8",
	//	label: "Visit us on Twitter",
	//	icon: "twitter",
	//},
	{
		link: "https://www.linkedin.com/company/freedom-services-group-limited/",
		label: "Visit us on LinkedIn",
		icon: "linkedin",
	},
	//{
	//	link: "https://www.instagram.com/freedomservicesgroup/",
	//	label: "Visit us on Instagram",
	//	icon: "instagram",
	//},
];

export default socialMedia;