import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const assets = require("siteAssets/pageManager").default("news-media-blog");
import { EmailLink } from "siteComponents";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerText={assets.headerText}>
	</LayoutPageTemplateHeaderImg>
);

const Blog = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage}>
			<LayoutPageTemplateSection>

			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection>

			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default Blog;
