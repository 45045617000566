import config from "config";
import React from "react";

const routeData = [
	{
		sitemapHeading: "",
		route: "/",

		component: require("siteViews/Landing").default,
	},

	{
		sitemapHeading: "About us",
		route: "/about-us",

		component: require("siteViews/AboutUs").default,
	},
	{
		sitemapHeading: "Our company",
		route: "/about-us/our-company",

		component: require("siteViews/AboutUs/OurCompany").default,
	},
	{
		sitemapHeading: "Our story",
		route: "/about-us/our-story",

		component: require("siteViews/AboutUs/OurStory").default,
	},
	{
		sitemapHeading: "Our team",
		route: "/about-us/our-team",

		component: require("siteViews/AboutUs/OurTeam").default,
	},
	{
		sitemapHeading: "Our values",
		route: "/about-us/our-values",

		component: require("siteViews/AboutUs/OurValues").default,
	},
	{
		sitemapHeading: "Innovation",
		route: "/about-us/innovation",

		component: require("siteViews/AboutUs/Innovation").default,
	},
	{
		sitemapHeading: "Women in finance",
		route: "/about-us/women-in-finance",

		component: require("siteViews/AboutUs/WomenInFinance").default,
	},

	{
		sitemapHeading: "Giving Back",
		route: "/giving-back",

		component: require("siteViews/GivingBack").default,
	},
	{
		sitemapHeading: "Our approach",
		route: "/giving-back/our-approach",

		component: require("siteViews/GivingBack/OurApproach").default,
	},
	{
		sitemapHeading: "Customers",
		route: "/giving-back/customers",

		component: require("siteViews/GivingBack/Customers").default,
	},
	{
		sitemapHeading: "Employees",
		route: "/giving-back/employees",

		component: require("siteViews/GivingBack/Employees").default,
	},
	{
		sitemapHeading: "Communities",
		route: "/giving-back/communities",

		component: require("siteViews/GivingBack/Communities").default,
	},
	{
		sitemapHeading: "Partners",
		route: "/giving-back/partners",

		component: require("siteViews/GivingBack/Partners").default,
	},
	{
		sitemapHeading: "Environment",
		route: "/giving-back/environment",

		component: require("siteViews/GivingBack/Environment").default,
	},

	{
		sitemapHeading: "News &amp; media",
		route: "/news-and-media",

		component: require("siteViews/NewsAndMedia").default,
	},
	{
		sitemapHeading: "Latest news",
		route: "/news-and-media/latest-news",

		component: require("siteViews/NewsAndMedia/LatestNews").default,
	},
	{
		sitemapHeading: "Blog",
		route: "/news-and-media/blog",

		component: require("siteViews/NewsAndMedia/Blog").default,
	},
	{
		sitemapHeading: "Podcast",
		route: "/news-and-media/podcast",

		component: require("siteViews/NewsAndMedia/Podcast").default,
	},

	{
		sitemapHeading: "Careers",
		route: "/careers",

		component: require("siteViews/Careers").default,
	},
	{
		sitemapHeading: "Why work for us",
		route: "/careers/why-work-for-us",

		component: require("siteViews/Careers/WhyWorkForUs").default,
	},
	{
		sitemapHeading: "Freedom life",
		route: "/careers/freedom-life",

		component: require("siteViews/Careers/FreedomLife").default,
	},
	{
		sitemapHeading: "Diversity &amp; Inclusion",
		route: "/careers/diversity-and-inclusion",

		component: require("siteViews/Careers/DiversityAndInclusion").default,
	},
	{
		sitemapHeading: "Current vacancies",
		route: "/careers/current-vacancies",

		component: require("siteViews/Careers/CurrentVacancies").default,
	},

	{
		sitemapHeading: "Contact Us",
		route: "/contact-us",

		component: require("siteViews/ContactUs").default,
	},
].filter((x) => x !== undefined);

export default routeData;
