import React from "react";
import { useResolvedPath, useMatch } from "react-router-dom";

import { NavLink } from "siteComponents";

const CheckCurrentPage = (routepath) => {

    let resolved = useResolvedPath(routepath);
    let isCurrentPage = useMatch({ path: resolved.pathname, end: true });

    if (isCurrentPage) {
        return { ariaCurrent: "page", isCurrent: true };
    }

    return { ariaCurrent: undefined, isCurrent: false };
}

const NavWithSubMenu = (props) => {

    const { label, route, className, items, dataBsToggle, dataBsTarget, switchBreakpoint } = props;

    const _className = ["nav-item", className]
        .filter((x) => x)
        .join(" ");

    var currentPage = CheckCurrentPage(route);

    return (
        <>
            <li key={route} className={_className}>
                <NavLink
                    label={label}
                    routepath={route}
                    dataBsToggle={dataBsToggle}
                    dataBsTarget={dataBsTarget}
                />
                {items && currentPage.isCurrent && (
                    <ul role="menu" className="d-flex">
                    {
                        items.map((item, i) => {
                            return (
                                <li
                                    key={i}
                                    className={`nav-item product-nav d-${switchBreakpoint}-block`}
                                >
                                    <NavLink
                                        label={item.label}
                                        routepath={item.route}
                                        dataBsToggle={dataBsToggle}
                                        dataBsTarget={dataBsTarget}
                                    />
                                </li>
                            );
                        })
                        }
                    </ul>
                )}
            </li>
        </>
    );
}

export default NavWithSubMenu;