import _ from "lodash";
import axios from "axios";
import config from "config";

/*
https://dev.azure.com/FreedomServicesGroup/Odyssey/_wiki/wikis/Odyssey.wiki/185/Callback-API


Available methods:
	
GetProductTypeList: GET
GetAvailableDates: GET	
SendCallbackRequest: POST
*/

import { createServiceObj } from "@library/common/helpers/service";

const { endPoints } = config;

const baseFunctions = createServiceObj(endPoints);

const siteServices = {
	...baseFunctions,

};

export default siteServices;