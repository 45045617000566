import React, { useCallback } from "react";

import companyManager from "siteManagers/companies";
import { ExternalLink, BaseCard, HeaderBreakoutTop, Body, TitleTwoColumns, Footer } from "siteComponents";

const keyList = [
	{ key: "description", label: "Description" },
	{ key: "experience", label: "Experience" },
	{ key: "location", label: "Location" },
	{ key: "salary", label: "Salary" },
];

const CareerKeyValue = (props) => {
	const { label, value } = props;

	return (
		<>
			<div className="col-12 col-md-3 col-lg-2 label pb-md-3">
				{label}
			</div>
			<div className="col-12 col-md-9 col-lg-10 value pb-3">
				{value}
			</div>
		</>
	);
}

const CareerDisplayComponent = (props) => {
	const { title, companyKey, className } = props;

	const companyData = useCallback(
		companyKey ? companyManager.getCompanyData(companyKey) : {},
		[companyKey]
	);

	console.log(title);

	const _className = [
		"career-item",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	const { logoPath, displayName } = companyData;

	return (
		<BaseCard
			cardClasses={_className}
			addBottomMargin={false}
		>
			<HeaderBreakoutTop isCentred={false}>
				<h3>{title}</h3>
			</HeaderBreakoutTop>
			<Body
				titleContent={(
					<TitleTwoColumns
						leftSide={(<h3>{displayName}</h3>)}
						rightSide={logoPath && <img className="img-fluid max-h-3rem" src={logoPath} />}
					/>
				)}
			>
				<div className="row vacancy-details">
					{keyList.map(({ key, label }) => {
						const value = props[key];
						if (!value) return null;

						return (
							<CareerKeyValue key={label}
								label={label}
								value={props[key]}
							/>
						);
					})}
				</div>

			</Body>
			<Footer isCentered={false} className="">
				<ExternalLink href="http://someurl.com" label="Apply" className="btn btn-fb" />
			</Footer>
		</BaseCard>
	);
};

export default CareerDisplayComponent;
