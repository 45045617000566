import React, { useCallback } from "react";
import moment from "moment";

import { ExternalLink, BaseCard, HorizontalCard, Body, Title } from "siteComponents";

const CommunityDisplayComponent = (props) => {
	const { title, description, url, timeStamp, imageUrl, imageAlt, className } = props;

	const _className = [
		"community-item",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<BaseCard
			cardClasses={_className}
			fullHeight={false}
		>
			<HorizontalCard
				imageUrl={imageUrl}
				imageAlt={imageAlt}
			>
				<Body
					titleContent={(<Title content={title} centerTitle={false} />)}
				>
					{description}
				</Body>
			</HorizontalCard>			
		</BaseCard>
	);
};

export default CommunityDisplayComponent;
