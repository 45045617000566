import React, { useCallback } from "react";
import moment from "moment";

import { ExternalLink, BaseCard, Header, Footer, HorizontalCard, Body, Title } from "siteComponents";

const DisplayComponent = (props) => {
	const { title, description, url, location, timeStamp, imageUrl, imageAlt, className, colWidth, } = props;

	const _className = [
		"latest-news-item",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className="col">
			<BaseCard
				cardClasses={_className}
				fullHeight={false}
			>
				<Header>
					{title}
				</Header>
				<Body className="text-center">
					{description}
				</Body>
				<Footer>
					<ExternalLink href={url} label="Read more" />
				</Footer>
			</BaseCard>
		</div>
	);
};

export default DisplayComponent;
