import React from "react";
import axios from "axios";
import _ from "lodash";

//todo: Move this into a library?
class feedClass {
	constructor(args = {}) {
		["name", "feeds"].forEach((k) => {
			if (!(k in args)) {
				this.consoleDebug();
				throw `Error in feedClass -- missing "${k}"`;
			}
		});

		this.name = args.name;
		this.feeds = args.feeds;

		console.log("feedClass created");
	}

	consoleDebug() {
		console.groupCollapsed("feedClass: ", this.name);

		// output the feeds
		Object.entries(this.feeds).forEach(([key, data]) => {
			console.groupCollapsed("key", ":", key);
			Object.entries(data).forEach(([subKey, subData]) =>
				console.log(subKey, ":", subData)
			);
			console.groupEnd();
		});
		console.groupEnd();
	}

	getFeed(feedNameList = [], options = {}) {
		// returns a promise()

		const sortFunction = (a, b) => {
			if (timeStampKey === undefined) return 0;
			return b[timeStampKey] - a[timeStampKey];
		};
		const { useDisplayComponent = true, timeStampKey = undefined } = options;

		const _feedNameList = _.isArray(feedNameList)
			? feedNameList
			: [feedNameList];

		return Promise.all(
			_feedNameList.map((feedName) => {
				const feedData = this.feeds[feedName];

				let feedResult;

				if (!feedData) {
					this.consoleDebug();
					throw `Error in feedClass.getFeed() -- unknown feedName "${feedName}"`;
				}

				if (feedData.axiosOptions) {
					// console.log("calling axios", feedData.axiosOptions);
					feedResult = axios(feedData.axiosOptions);
				} else if (feedData.staticData) {
					feedResult = new Promise((resolve) => resolve(feedData.staticData));
				}

				// console.log("feedName:", feedName, "=", feedData);
				return feedResult
					.then((result) => {
						if (!feedData.postProcess) return result;

						let newResult = _.cloneDeep(result);
						feedData.postProcess.forEach((myFn) => {
							newResult = myFn(newResult);
						});

						return newResult;
					})
					.then((result) => {
						// console.log("result", result);
						if (useDisplayComponent) {
							const { DisplayComponent } = feedData;

							return result
								.sort(sortFunction)
								.map((x, i) => (
									<DisplayComponent key={`${feedName}_${i}`} {...x} />
								));
						}

						return result.sort(sortFunction);
					});
			})
		);
	}
}

export default feedClass;
