import feedClass from "./feedClass";
import config from "config";

const feeds = {
	samPodcast: {
		//https://www.spreaker.com/show/3663162/episodes/feed
		//https://podcasts.apple.com/gb/podcast/human-business-with-sam-white/id1476366810
		// https://superuser.com/questions/78415/get-rss-feed-from-itunes-podcast-links

		title: "Sam Podcast",
		DisplayComponent: require("./Components/SamPodcast").default,

		// BELOW: Test call against AWS Q&B site
		axiosOptions: {
			method: "post",
			url: `${config.aws.endPoint}/GetFrontEndLists`,
			headers: {
				"x-api-key": config.aws.apiKey,
			},
			data: {
				ListName: "person-title",
				Operation: "single",
			},
		},
		postProcess: [
			(response) => (response ? response.data.ListOption : []),
			(response) =>
				response.map((x) => ({
					title: x.Value,
					description: x.Description,
					url: "https://www.google.co.uk",
					timeStamp: new Date("21 jan 2020"),
				})),
		],
		// ABOVE: Test call against AWS Q&B site

		// staticData: [
		// 	{
		// 		title: "Podcast title",
		// 		description: "xxxxxxxxxxx",
		// 		url: "https://www.google.co.uk",
		// 		timeStamp: new Date("21 jan 2020"),
		// 	},
		// ],
	},
	career: {
		title: "Career feeds",
		url: "xxxxxx",
		DisplayComponent: require("./Components/Career").default,
		axiosOptions: {
			method: "post",
			url: `${config.aws.endPoint}/GetFrontEndLists`,
			headers: {
				"x-api-key": config.aws.apiKey,
			},
			data: {
				ListName: "person-title",
				Operation: "single",
			},
		},
		postProcess: [
			(response) => (response ? response.data.ListOption : []),
			(response) =>
				response.map((x) => ({
					title: x.Value,
					companyKey: x.Value.includes("F") ? "pukka" : x.Value.includes("Dr") ? "action" : "freedomBrokers",
					description: x.Description,
					experience: "experiencexxxxxxxxxxx",
					location: "locationxxxxxxxxxx",
					salary: "salary£xxxxxx per year",
					timeStamp: new Date("21 jan 2020"),
				})),
		],

		// staticData: [
		// 	// Example data -- will be feed eventually
		// 	{
		// 		title: "Customer service operator (NEWER)",
		// 		description: "xxxxxxxxxxx",
		// 		experience: "xxxxxxxxxxx",
		// 		location: "xxxxxxxxxx",
		// 		salary: "£xxxxxx per year",
		// 		timeStamp: new Date("21 jan 2020"),
		// 		companyKey: "pukka",
		// 	},
		// 	{
		// 		title: "Customer service operator",
		// 		description: "xxxxxxxxxxx",
		// 		experience: "xxxxxxxxxxx",
		// 		location: "xxxxxxxxxx",
		// 		salary: "£xxxxxx per year",
		// 		timeStamp: new Date("1 jan 2020"),
		// 		companyKey: "pukka",
		// 	},
		// 	{
		// 		title: "Customer service operator (NEWEST)",
		// 		description: "xxxxxxxxxxx",
		// 		experience: "xxxxxxxxxxx",
		// 		salary: "£xxxxxx per year",
		// 		timeStamp: new Date("22 jan 2020"),
		// 		companyKey: "pukka",
		// 	},
		// ],
	},

	community: {
		
		title: "Community Activity",
		DisplayComponent: require("./Components/Community").default,

		// BELOW: Test call against AWS Q&B site
		axiosOptions: {
			method: "post",
			url: `${config.aws.endPoint}/GetFrontEndLists`,
			headers: {
				"x-api-key": config.aws.apiKey,
			},
			data: {
				ListName: "person-title",
				Operation: "single",
			},
		},
		postProcess: [
			(response) => (response ? response.data.ListOption : []),
			(response) =>
				response.map((x) => ({
					title: x.Value,
					description: x.Description,
					location: "location",
					url: "https://www.google.co.uk",
					imageUrl: "https://assets.website-files.com/5dc03ae755334733aa9457a6/5dd29ab8c5a7ed595163a7ef_happy%20mind.jpg",
					imageAlt: "My Happy Mind",
					timeStamp: new Date("21 jan 2020"),
				})),
		],
		// ABOVE: Test call against AWS Q&B site

	},

	latestNews: {

		title: "Latest News",
		DisplayComponent: require("./Components/LatestNews").default,

		// BELOW: Test call against AWS Q&B site
		axiosOptions: {
			method: "post",
			url: `${config.aws.endPoint}/GetFrontEndLists`,
			headers: {
				"x-api-key": config.aws.apiKey,
			},
			data: {
				ListName: "person-title",
				Operation: "single",
			},
		},
		postProcess: [
			(response) => (response ? response.data.ListOption : []),
			(response) =>
				response.map((x) => ({
					title: x.Value,
					description: x.Description,
					location: "location",
					url: "https://www.google.co.uk",
					imageUrl: "https://assets.website-files.com/5dc03ae755334733aa9457a6/5dd29ab8c5a7ed595163a7ef_happy%20mind.jpg",
					imageAlt: "My Happy Mind",
					timeStamp: new Date("21 jan 2020"),
				})),
		],
		// ABOVE: Test call against AWS Q&B site

	},

	latestNewsTeaser: {

		title: "Latest News",
		DisplayComponent: require("./Components/LatestNewsTeaser").default,

		// BELOW: Test call against AWS Q&B site
		axiosOptions: {
			method: "post",
			url: `${config.aws.endPoint}/GetFrontEndLists`,
			headers: {
				"x-api-key": config.aws.apiKey,
			},
			data: {
				ListName: "person-title",
				Operation: "single",
			},
		},
		postProcess: [
			(response) => (response ? response.data.ListOption : []),
			(response) =>
				response.slice(0, 4).map((x) => ({
					title: "Some exciting news",
					description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
					location: "location",
					url: "https://www.google.co.uk",
					imageUrl: "https://assets.website-files.com/5dc03ae755334733aa9457a6/5dd29ab8c5a7ed595163a7ef_happy%20mind.jpg",
					imageAlt: "News",
					timeStamp: new Date("21 jan 2020"),
				})),
		],
		// ABOVE: Test call against AWS Q&B site

	},
};

const siteFeeds = new feedClass({ name: "Main site feed", feeds });

export default siteFeeds;
