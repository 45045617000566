import React from "react";

import { BaseCard, Body } from "siteComponents";

const PageHeaderCard = ({
	className,
	text,
}) => {

	if (!text) {
		return null;
    }
	const _className = [
		"page-intro-card",
		"align-self-end",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<BaseCard
			cardClasses={_className}
			fullHeight={false}
			addBottomMargin={false}
		>
			<Body>
				{text}
			</Body>
			
		</BaseCard>
	);
};

export default PageHeaderCard;
