import React from "react";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeaderImg from "siteLayouts/Page/templates/HeaderImg";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

import journey from "siteAssets/images/journey.png";

const assets = require("siteAssets/pageManager").default("about-us-our-story");
import { EmailLink } from "siteComponents";
import Sections from "../Sections";

const Header = () => (
	<LayoutPageTemplateHeaderImg
		headerCardText={assets.headerCardText}>
	</LayoutPageTemplateHeaderImg>
);

const OurStory = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header} headerImage={assets.headerImage} className={assets.className}>
			<LayoutPageTemplateSection
				headerText="Where we started"
			>
				<p>
					The Freedom story started way back in 1999 with our founder and chair Sam White.
				</p>
				<p>
					After leaving university and starting her first job in the insurance sector, Sam realised
					quickly what it was like being a woman in a male-dominated industry. Deciding she wanted
					to do things differently and carve out a path for herself, she set up her first business
					- a claims management business. Action Claims and Mortgages focused on providing outsourced
					non-fault first notification service to UK brokers.
				</p>
				<p>
					In 2012, in response to upcoming personal injury claim reforms, Action switched to providing
					third part intervention service to insurers to reduce and mitigate the cost of the claim when
					their driver is at fault.
				</p>
				<p>
					The next few years saw a period of growth. Pukka Services was founded in 2016 - an MGA providing
					van and private car insurance to UK customers. And, in 2017 Freedom Brokers was formed to provide
					distribution for Pukka and other insurance panel members via direct sales and aggregators.
				</p>
				<p>
					Action 365 also expanded its third-party claims administration service with delegated authority
					from capacity providers, full end to end claims management, and a focus on loss ration control.
				</p>
			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection
				headerText="Where are we now"
			>
				<p>
					In 2022, Sam took a step back, replacing herself with Andrew Tailby as CEO. Andrew had been working
					with the senior leadership over a few years as a coach and mentor.
				</p>
				<p>
					An egalitarian structure in the business means that the staff make all the decisions, with the CEO
					facilitate them making the decisions.
				</p>
				<p>
					With a new CEO and executive team in place, a growing number of employees and ambitious plans for growth,
					the future is looking bright for Freedom services.
				</p>
			</LayoutPageTemplateSection>

			<LayoutPageTemplateSection>
				<Sections />
			</LayoutPageTemplateSection>
		</LayoutPage>
	);
};

export default OurStory;
