import React from "react";

import HyperlinkOrig from "libraryComponentsForm/Hyperlink";

const Hyperlink = (props) => {
	const { className = "", ...otherProps } = props;
	return (
		<HyperlinkOrig
			className={`freedom-hyperlink ${className}`}
			{...otherProps}
		/>
	);
};

export default Hyperlink;