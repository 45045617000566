import React from "react";

import { ExternalLink, BaseCard, HeaderBreakoutTop, Body, Footer } from "siteComponents";

const DisplayLink = (props) => {
	const { linkToDisplay, urlContact, urlFurtherInfo } = props;

	switch (linkToDisplay) {
		case "contact": {
			return (
				<ExternalLink href={urlContact} label="Contact details" className="btn btn-primary" />
			);
		}
	}

	return (
		<ExternalLink href={urlFurtherInfo} label="Find out more" className="btn btn-primary" />
	);
}

const CompanyItem = ({
	displayName, className,
	logoPath,
	details,
	displayLink = false,
	linkToDisplay,
	urlFurtherInfo,
	urlContact,
}) => {

	const _className = [
		"company-item",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<BaseCard
			cardClasses={_className}
			fullHeight={true}			
		>
			<HeaderBreakoutTop>
				<img src={logoPath} className="" alt={displayName} />
			</HeaderBreakoutTop>
			<Body>
				{details}
			</Body>
			{displayLink && (
				<Footer className="bg-transparent bt-0 pb-3">
					<DisplayLink
						linkToDisplay={linkToDisplay}
						urlFurtherInfo={urlFurtherInfo}
						urlContact={urlContact} />
				</Footer>
			)}			
		</BaseCard>
	);
};

export default CompanyItem;
