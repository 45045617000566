import React, { useEffect, useState } from "react";
import FormTemplate from "./FormTemplate";
import { ModalLoadingOpened } from "siteComponents";

const constStates = {
	start: "start",
	submitting: "submitting",
	submitFailed: "failed",
	complete: "complete",
};

const Callback = (props) => {
	// console.log("props", props);

	require("@library/common/helpers/misc/argChecker")(props, [
		"fnGetContactLists",
		"fnSubmit",
	]);

	const [curState, setCurState] = useState(constStates.start);

	//*** Client variable */
	// const [formIsValid, setFormIsValid] = useState(false);
	const [formQueryType, setFormQueryType] = useState();
	const [formDescription, setFormDescription] = useState();
	const [formName, setFormName] = useState();
	const [formPhonenumber, setFormPhonenumber] = useState();
	const [formEmail, setFormEmail] = useState();
	const [formPolicynumber, setFormPolicynumber] = useState();
	const [formProduct, setFormProduct] = useState();

	const formProps = {
		formQueryType,
		setFormQueryType,
		formDescription,
		setFormDescription,
		formName,
		setFormName,
		formEmail,
		setFormEmail,
		fnGetContactLists: props.fnGetContactLists,
		fnSubmit: () => {
			/*
			{ 
				"QueryType": "CLM",
				"Description": "This is a test",
				"Name": "Sam",
				"Email": "sam@fsg.co.uk",
				"PhoneNumber": "07999123456",
				"ProductType": "GAP",
				"PolicyNumber": "ABC123"
			}
			*/

			const payload = {
				QueryType: formQueryType,
				Description: formDescription,
				Name: formName,
				Email: formEmail,
			};
			console.log("submitting:", payload);

			setCurState(constStates.submitting);

			props
				.fnSubmit(payload)
				.then((response = {}) => {
					console.log("response", response);
					const { Status } = response;

					if (Status === "SUCCESS") {
						setCurState(constStates.complete);
					} else {
						setCurState(constStates.submitFailed);
					}
				})
				.catch((e) => {
					setCurState(constStates.submitFailed);
				});
		},
	};

	const resetForm = () => {
		setFormQueryType(undefined);
		setFormDescription(undefined);
		setFormName(undefined);
		setFormEmail(undefined);
		setCurState(constStates.start);
	};

	if (false) {
		useEffect(() => {
			// setFormQueryType("TEST");
			setFormDescription("Test Description");
			setFormName("Mr Test");
			setFormPhonenumber("07777777777");
			setFormEmail("test@test.com");
			setFormPolicynumber("0123456789");
			// setFormProduct("PC");
			setCurState(constStates.complete);
		}, []);
	}

	// DEBUG INFO
	console.groupCollapsed("Callback vars");
	[
		"constStates",
		"curState",
		"formQueryType",
		"formDescription",
		"formName",
		"formEmail",
	].forEach((x) => console.log(x, ":", eval(x)));
	console.groupEnd();

	// return <ModalLoadingOpened />;

	if (curState === constStates.submitting)
		return (
			<span>
				<ModalLoadingOpened />
				<FormTemplate {...formProps} />
			</span>
		);

	if (curState === constStates.submitFailed)
		return (
			<FormTemplate
				{...formProps}
				feedbackMessage={
					<div className="alert alert-danger">
						We're sorry, something's gone wrong. Please try again.
					</div>
				}
			/>
		);

	if (curState === constStates.complete)
		return (
			<div className="row">
				<p>Thank you, your query has been sent. </p>
				<p className="mb-4">
					A member of our team will be in touch with you soon.
				</p>
				<div className="col-12">
					<button
						className="btn btn-primary"
						onClick={() => {
							resetForm();
						}}
					>
						Send another query
					</button>
				</div>
			</div>
		);

	return <FormTemplate {...formProps} />;
};

export default Callback;
