import React from "react";

const LayoutPageTemplateSection = (props) => {
	const { children, headerText, bgColour, className } = props;

	const _classes = [
		"row",
		"justify-content-center",
		bgColour ? "bg-" + bgColour : "",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className={_classes}>
			<div className="col-12 section-heading">
				<h2>{headerText}</h2>
			</div>
			<div className="col-12 section-content">
				{children}
			</div>
		</div>
	);
};

export default LayoutPageTemplateSection;
