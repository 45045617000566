import React from "react";

class DatabaseItem {
	constructor(args = {}) {
		const requiredKeys = ["displayName", "details"];
		const optionalKeys = ["logoPath", "urlFurtherInfo", "urlContact", "className"];

		requiredKeys.forEach((k) => {
			if (!(k in args)) {
				throw `Error in DatabaseItem -- missing ${k}`;
			}
			this[k] = args[k];
		});

		optionalKeys.forEach((k) => {
			this[k] = args[k];
		});
	}
}

const companyDatabase = {
	pukka: new DatabaseItem({
		displayName: "Pukka Insurance",
		className: "pukka",
		logoPath: require("siteAssets/images/logos/pukka-logo.webp"),
		urlFurtherInfo: "https://www.pukka.co.uk/",
		urlContact: "https://www.pukka.co.uk/contact-us",
		details: (
			<>
				<div>
					Competitive motor vehicle insurance available to the UK broker insurance market.
				</div>
			</>
		),
	}),
	action: new DatabaseItem({
		displayName: "Action",
		className: "action",
		logoPath: require("siteAssets/images/logos/action-logo.webp"),
		urlFurtherInfo: "https://www.action365.co.uk/",
		urlContact: "https://www.action365.co.uk/#contactus",
		details: (
			<>
				<div>
					Providing the best possible motor claims accident management services for 
					insurers, brokers and hire companies. 
				</div>
			</>
		),
	}),
	freedomBrokers: new DatabaseItem({
		displayName: "Freedom Brokers",
		className: "freedom",
		logoPath: require("siteAssets/images/logos/brokers-logo.webp"),
		urlFurtherInfo: "https://www.freedombrokers.co.uk",
		urlContact: "https://www.freedombrokers.co.uk/contact-us",
		details: (
			<>
				<div>
					Straight forward private and commercial motor insurance with policies tailor 
					made for you. We have access to a wide range of insurers ensuring the best 
					possible price.
				</div>
			</>
		),
	}),
};

export default companyDatabase;
