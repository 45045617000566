import React from "react";

const TitleContent = (props) => {

    const { content, isCentered = true } = props;

    const _classes = [
        "card-title",
        "fw-bold",
        "fs-5",
        "text-grey",
        isCentered ? "p-0" : ""
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    return (
        <h3 className={_classes}>
            {content}
        </h3>  
    );
}

const Title = (props) => {

    const { content, className, centerTitle = true } = props;



    if (centerTitle) {
        return (
            <div className="row text-center">
                <TitleContent content={content} isCentered={true} />
            </div>
        );
    }

    return (
        <TitleContent content={content} isCentered={false} />
    );

}

export default Title;