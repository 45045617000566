import React from "react";

const Body = (props) => {

    const { children, className, clickAction, titleContent } = props;

    const _classes = [
        "card-body",
        className
    ]
        .filter((x) => x !== undefined && x !== "")
        .join(" ");

    if (clickAction) {
        return (
            <div className={_classes} onClick={clickAction}>
                {titleContent}
                {children}
            </div>
        );
    }

    return (
        <div className={_classes}>
            {titleContent}
            {children}
        </div>
    );

}

export default Body;