import React from "react";

const PhoneNumberLink = (props) => {

	const { phonenumber, altLabel, className = "", isLink = false, isStrong = true } = props;

	if (!isLink) {
		if (isStrong) {
			return (<strong className="tel-no-link whitespace-no-wrap">{phonenumber}</strong>);
		}
		return (
			<span className="tel-no-link whitespace-no-wrap">{phonenumber}</span>
		);
	}

	const displayText = altLabel != undefined ? altLabel : phonenumber;
	const display = isStrong ? <strong>{displayText}</strong> : <>{displayText}</>;

	const linkNumber = phonenumber.replaceAll(" ", "");
	return (
		<a
			className={`tel-link whitespace-no-wrap ${className}`}
			href={`tel:${linkNumber}`}
		>
			{display}
		</a>
	);
}

export default PhoneNumberLink;