import React from "react";

import siteFeeds from "siteManagers/feed";
import FeedViewer from "siteManagers/feed/Viewer";

import LayoutPage from "siteLayouts/Page";
import LayoutPageTemplateHeader from "siteLayouts/Page/templates/Header";
import LayoutPageTemplateSection from "siteLayouts/Page/templates/Section";

const Header = () => (
	<LayoutPageTemplateHeader headerText="Social responsibility is more than just a corporate commitment for us.">
		<p>
			We support grass-roots charities through sharing our profits, and actively encourage our team to volunteers in the community.
		</p>

	</LayoutPageTemplateHeader>
);

const GivingBack = (props) => {
	// const { children } = props;

	return (
		<LayoutPage Header={Header}>
			<LayoutPageTemplateSection
				headerText="Here's what we have been up to recently"
			>
				<FeedViewer
					func={siteFeeds.getFeed("community", {
						useDisplayComponent: true,
						timeStampKey: "timeStamp",
					})}
					LoadingComponent={() => (
						<div className="alert alert-info">Checking for updates...</div>
					)}
				/>

			</LayoutPageTemplateSection>
			
		</LayoutPage>
	);
};

export default GivingBack;
