import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import { BaseCard, Body, Title } from "siteComponents";

const DisplayComponent = (props) => {
	const { title, description, route, className, } = props;

	const navigate = useNavigate();

	const _className = [
		"tile",
		"text-center",
		className,
	]
		.filter((x) => x !== undefined && x !== "")
		.join(" ");

	return (
		<div className="col">
			<BaseCard
				cardClasses={_className}
				fullHeight={true}
				cardOnClick={() => navigate(route)}
			>
				<Body titleContent={(<Title content={title} centerTitle={true} />)}>
					{description}
				</Body>
			</BaseCard>
		</div>
	);
};

export default DisplayComponent;
